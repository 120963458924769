import ordersApi from "@/api/orders";

const state = {
  isLoading: false,
  isOrderLoading: false,
  isStatusLoading: false,
  order: {},
  orders: [],
  errors: [],
};

const mutations = {
  resetOrdersErrors(state) {
    state.errors = [];
  },
  getOrdersStart(state) {
    state.orders = [];
    state.order = {};
    state.isLoading = true;
    state.errors = [];
  },
  getOrdersSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.orders = payload;
  },
  getOrdersFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getOrderStart(state) {
    state.orders = [];
    state.isOrderLoading = true;
    state.errors = [];
  },
  getOrderSuccess(state, payload) {
    state.isOrderLoading = false;
    state.errors = [];
    state.order = payload;
  },
  getOrderFailed(state, payload) {
    state.isOrderLoading = false;
    state.errors = payload;
  },

  actionOrderStart(state) {
    state.isStatusLoading = true;
    state.errors = [];
  },
  actionOrderSuccess(state, payload) {
    state.isStatusLoading = false;
    state.order = payload;
  },
  actionOrderFailed(state, payload) {
    state.isStatusLoading = false;
    state.errors = payload;
  },
};

const actions = {
  async getOrders(context, payload) {
    try {
      context.commit("getOrdersStart");

      const res = await ordersApi.getOrders(payload);
      context.commit("getOrdersSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOrdersFailed", e.response.data.error);
      return false;
    }
  },

  async getOrder(context, payload) {
    try {
      context.commit("getOrderStart");

      const res = await ordersApi.getOrder(payload);
      context.commit("getOrderSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOrderFailed", e.response.data.error);
      return false;
    }
  },

  async actionOrder(context, payload) {
    try {
      context.commit("actionOrderStart");

      const res = await ordersApi.orderAction(
        payload.orderId,
        payload.requestBody
      );
      context.commit("actionOrderSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("actionOrderFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
