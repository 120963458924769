import axios from "@/plugins/axios";

const urlPath = "/partner/api/v1/profile";
const url2fa = "/partner/api/v1/profile/2fa/otp";

const resendEmail = () => {
  return axios.post(`${urlPath}/resend-email-confirmation`);
};

const getProfile = () => {
  return axios.get(`${urlPath}`);
};

const updateProfile = (data) => {
  return axios.put(`${urlPath}`, data);
};

const updateProfileEmail = (data) => {
  return axios.put(`${urlPath}/email`, data);
};

const updateAvatar = (data) => {
  return axios({
    method: "post",
    url: `${urlPath}/picture`,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};

const updatePassword = (data) => {
  return axios.post(`${urlPath}/password`, data);
};

const getCodes = () => {
  return axios.get(`${url2fa}/recovery-codes`);
};

const generateCodes = () => {
  return axios.post(`${url2fa}/generate-recovery-codes`);
};

const getQr = () => {
  return axios.get(`${url2fa}/prepare`);
};

const downloadCodes = () => {
  return axios.get(`${url2fa}/recovery-codes/download`);
};

const enableTwoFa = (data) => {
  return axios.post(`${url2fa}/enable`, data);
};

const disableTwoFa = () => {
  return axios.post(`${url2fa}/disable`);
};

export default {
  resendEmail,
  getProfile,
  updateProfile,
  updateAvatar,
  updatePassword,
  getCodes,
  getQr,
  downloadCodes,
  enableTwoFa,
  disableTwoFa,
  generateCodes,
  updateProfileEmail,
};
