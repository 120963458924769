import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/DashboardView.vue"),
  },
  {
    path: "/sign-in",
    name: "Sign in",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/SignIn/SignIn.vue"),
  },
  {
    path: "/sign-in/otp",
    name: "OTP code",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/SignIn/OtpView.vue"),
  },
  {
    path: "/sign-in/otp-recovery",
    name: "OTP recovery code",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/SignIn/OtpRecovery.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/SignIn/ForgotPassword.vue"),
  },
  {
    path: "/reset-password",
    name: "Password update",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/SignIn/ResetPassword.vue"),
  },
  {
    path: "/email-confirmation",
    name: "Email confirmation",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/SignIn/EmailConfirmation.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Profile.vue"),
  },
  {
    path: "/account",
    name: "Account",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Account.vue"),
  },
  {
    path: "/offerings",
    name: "Offerings",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/OfferingsList.vue"),
  },
  {
    path: "/offerings/:id",
    name: "Offering",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/OfferingDetail.vue"),
  },
  {
    path: "/orders",
    name: "Orders",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/OrdersList.vue"),
  },
  {
    path: "/orders/:id",
    name: "Order",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/OrderDetail.vue"),
  },
  {
    path: "*",
    redirect: { name: "Dashboard" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
