<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>
<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";

export default {
  components: { EmptyLayout, MainLayout },

  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  },
};
</script>

<style lang="scss">
#app {
  * {
    font-family: "Frutiger", sans-serif !important;
  }
  .arabic-black {
    font-family: "Frutiger Black", sans-serif !important;
  }
  .arabic-bold {
    font-family: "Frutiger Bold", sans-serif !important;
  }
  .arabic-light {
    font-family: "Frutiger Light", sans-serif !important;
  }
  .arabic-default {
    font-family: "Frutiger", sans-serif !important;
  }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state),
  > .v-input__control,
  > .v-input__slot,
  fieldset {
    color: #b0c3cc;
  }
}

.v-picker__body {
  max-width: 400px !important;
  width: 300px !important;
}

.v-date-picker-table--month {
  overflow-y: auto;
  overflow-x: hidden;
}

.v-date-picker-header > button > .v-btn__content {
  color: black !important;
}

.v-date-picker-table--month {
  & td {
    width: 100% !important;
  }
  & tr {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & button {
    min-width: fit-content !important;
    padding: 0 10px !important;
  }

  & .v-btn__content {
    //color: black !important;
  }
}

.v-application {
  & p {
    margin-bottom: 0 !important;
  }
}

.phone-container {
  & .v-input__prepend-inner {
    margin-top: 12px !important;
    padding: 0 !important;
  }
}

.search-input {
  & .v-input__prepend-inner {
    margin-top: 12px !important;
  }
}

input {
  padding: 0;
}
.v-btn {
  letter-spacing: 0 !important;
}

.btn-shadow {
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1) !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-25 {
  font-size: 25px;
}

.f-30 {
  font-size: 30px !important;
}

.f-32 {
  font-size: 32px !important;
}

.f-36 {
  font-size: 36px !important;
}

.line-140 {
  line-height: 140%;
}

.btn-text {
  text-transform: lowercase;
  letter-spacing: 0 !important;
  &::first-letter {
    text-transform: capitalize;
  }
  font-size: 1rem;
  font-weight: 700;
}

.fl-uppercase {
  text-transform: lowercase;
  &::first-letter {
    text-transform: capitalize;
  }
}

.error-btn {
  color: #c90000 !important;
  background-color: #ffffff !important;
  border: 1px solid #c90000;
  border-radius: 8px;
}

.link {
  cursor: pointer;
}

.btn-underline {
  position: relative;
  width: fit-content;
  &::after {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    width: 100%;
    background: var(--v-purple-1-base);
  }
  &:hover:after {
    display: none;
  }
}

.blue-border {
  border: 1px solid #d8e5eb;
}

.radius-34 {
  border-radius: 34px !important;
}
.radius-22 {
  border-radius: 16px !important;
}

.radius-16 {
  border-radius: 16px !important;
}

.radius-12 {
  border-radius: 12px !important;
}

.radius-10 {
  border-radius: 10px !important;
}
.radius-8 {
  border-radius: 8px !important;
}
.radius-6 {
  border-radius: 6px !important;
}

.radius-4 {
  border-radius: 4px !important;
}

.radius-3 {
  border-radius: 3px !important;
}
.radius-2 {
  border-radius: 2px !important;
}
.radius-0 {
  border-radius: 0 !important;
}

.centered-input input {
  text-align: center;
}

.v-input .v-input__slot {
  border-radius: 8px !important;
  min-height: 40px !important;
  background-color: #fdfdfd !important;
}

.v-input__append-inner {
  align-self: center !important;
  margin: 0 !important;
}

.underline-link {
  display: inline-block;
  position: relative;

  &::after {
    content: "";
    height: 1px;
    width: 105%;
    background-color: #6c3cea;
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.v-window {
  overflow: unset !important;
}
.platform-wrapper {
  &.v-list {
    padding: 0 !important;
  }
  & .v-list-item {
    border-radius: 16px !important;
    border: 2px solid #b0c3cc;
    &--active {
      border: 2px solid #6c3cea !important;
    }
    &::before {
      border-radius: 16px !important;
    }
  }
}

.selected-mark {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  display: none;
}

.platform--selected {
  & .selected-mark {
    display: block;
  }
}

label {
  display: block;
  //margin-bottom: 6px !important;
}

.v-dialog {
  border-radius: 16px !important;
}

.v-text-field__suffix {
  color: #737b8b;
}

.close-btn {
  top: 24px;
  right: 24px;
}

.back-btn {
  top: 100px;
  left: 24px;
}

.mb-2px {
  margin-bottom: 2px;
}

.v-input__slot {
  margin-bottom: 4px !important;
}

.v-messages__message {
  font-size: 14px;
}

.v-text-field__details {
  margin-bottom: 6px !important;
}

.back-btn,
.close-btn {
  position: absolute !important;
}

.relative {
  position: relative;
}

.loader--white .v-progress-circular {
  color: white;
}

.hidden {
  overflow: hidden;
}
.pointer {
  cursor: pointer;
}

.divider-line {
  border-bottom: 1px solid #d8e5eb;
}

.alert-red {
  background-color: #ffeeee !important;
}

.width100 {
  width: 100%;
}

.height100 {
  height: 100%;
}

.text-center input {
  text-align: center !important;
}

.profile-info-card {
  background-color: #fff;
  box-shadow: 2px 4px 14px 1px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}

.main__wrapper {
  max-width: 1240px;
  width: 100%;
  padding: 16px 70px;
  margin: 0 auto;
}

.info__section {
  border: 1px solid #d8e5eb;
  border-radius: 12px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.06);
  background: white;
  padding: 40px;
  overflow: hidden;
}

.gray-border {
  border: 1px solid #d8e5eb !important;
}

.info__section--unset {
  overflow: unset !important;
}

.mr-100 {
  margin-right: 100px;
}

table {
  thead {
    tr {
      th {
        height: 42px !important;
        background-color: #f5f8fa !important;

        span {
          display: flex;
          align-items: center;
          padding-top: 10px;
          padding-bottom: 10px;
          font-size: 16px;
          color: var(--v-gray-1-base);
        }
      }
    }
  }
  tbody {
    td {
      height: 42px !important;
      font-size: 16px !important;
      vertical-align: middle;
      color: black;
    }
  }
}
.table-footer {
  .v-input {
    max-width: 85px;
  }
  .v-input__slot {
    min-height: 32px !important;
  }
}
#app {
  .v-pagination__navigation,
  .v-pagination__item,
  .v-pagination__item--active {
    box-shadow: none;
    margin: 5px 2px;
  }
  .v-pagination__item,
  .v-pagination__navigation {
    width: 34px;
    height: 34px;
    border: 1px solid var(--v-blue-1-base);
  }
}

.bar-chart-dropdown {
  padding: 12px 12px !important;
  border-radius: 8px !important;
  border: 1px solid #b0c3cc;
  background: #fdfdfd;
  color: transparent !important;

  .v-list-item__title {
    color: black !important;
  }

  .v-list-item:not(:last-child) {
    margin-bottom: 3px;
  }

  .v-list .v-list-item.primary--text {
    color: transparent !important;
    caret-color: transparent !important;
  }

  .v-list {
    padding: 0 !important;
  }

  .v-list-item--link:not(.v-list-item--active):before {
    border-radius: 4px !important;
    background-color: black !important;
  }

  .v-list-item--active {
    border-radius: 4px !important;
    background: #d8e5eb !important;
  }
}

.period-select .mdi-chevron-down.purple-1--text {
  color: rgba(0, 0, 0, 0.54) !important;
}

.dashed-divider {
  border: 0.5px dashed #d8e5eb !important;
}

.dashed-bb {
  border-bottom: 1px dashed #d8e5eb !important;
}

.period-select {
  position: absolute;
  right: 0;
  top: 25px;
  .v-input__prepend-inner {
    margin: auto !important;
  }

  .v-input__slot {
    margin: 0 !important;
  }

  .v-sheet.v-card {
    border-radius: 20px !important;
  }
  .v-text-field__details {
    display: none !important;
  }
  @media screen and (max-width: 700px) {
    display: none;
  }
}

.sales-chart {
  .graph-bar {
    background: #f7f7f7 !important;
  }
  border: 1px dashed #d8e5eb;
  background: #f7f7f7;
}
</style>
