<template>
  <v-card
    @click="checkAndClose()"
    data-notify="container"
    class="alert open"
    role="alert"
    rounded="lg"
    :class="[verticalAlign, horizontalAlign, type]"
    :style="customPosition"
    data-notify-position="top-right"
  >
    <div :class="`${type} lighten-5 d-flex px-5 py-4 align-center rounded-lg`">
      <img
        v-if="type === 'error'"
        data-notify="icon"
        :src="errorIcon"
        alt="error icon"
      />
      <img
        v-if="type === 'success'"
        data-notify="icon"
        :src="successIcon"
        alt="success icon"
      />
      <img
        v-if="type === 'warning'"
        data-notify="icon"
        :src="warningIcon"
        alt="warning icon"
      />
      <div class="ms-4">
        <div
          data-notify="title"
          class="font-weight-bold notification-title"
          v-html="title"
        ></div>
        <div
          data-notify="message"
          class="text--text text--caption"
          v-html="message"
        ></div>
      </div>
      <v-btn
        class="alert-btn ms-2"
        :class="{ 'align-self-start': type === 'newNotification' }"
        icon
        @click.stop="close"
      >
        <img
          v-if="type === 'newNotification'"
          src="@/assets/icons/grey-x-icon.svg"
          alt="close"
        />
        <v-icon v-else color="green">mdi-window-close</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>
<script>
import errorIcon from "@/assets/icons/error-icon.svg";
import successIcon from "@/assets/icons/success-icon.svg";
import warningIcon from "@/assets/icons/warning-icon.svg";
import { pushToNotificationUrl } from "@/helpers/notifications";

export default {
  name: "notification",
  props: {
    title: String,
    message: String,
    item: Object,
    icon: {
      type: String,
      default: "mdi-check-underline",
    },
    verticalAlign: {
      type: String,
      default: "top",
    },
    horizontalAlign: {
      type: String,
      default: "right",
    },
    type: {
      type: String,
      default: "success",
    },
    timeout: {
      type: Number,
      default: 2500,
    },
    timestamp: {
      type: Date,
      default: () => new Date(),
    },
  },
  data() {
    return {
      errorIcon,
      successIcon,
      warningIcon,
      elmHeight: 0,
    };
  },
  computed: {
    hasIcon() {
      return this.icon && this.icon.length > 0;
    },
    customPosition() {
      const initialMargin = 20;
      const alertHeight = this.elmHeight + 10;
      const sameAlertsCount = this.$notifications.state.filter((alert) => {
        return (
          alert.horizontalAlign === this.horizontalAlign &&
          alert.verticalAlign === this.verticalAlign &&
          alert.timestamp <= this.timestamp
        );
      }).length;
      const pixels = (sameAlertsCount - 1) * alertHeight + initialMargin;
      const styles = {};
      if (this.verticalAlign === "top") {
        styles.top = `${pixels}px`;
      } else {
        styles.bottom = `${pixels}px`;
      }
      return styles;
    },
  },
  methods: {
    close() {
      this.$emit("on-close", this.timestamp);
    },

    checkAndClose() {
      if (this.type === "newNotification") {
        if (!this.item.seen) {
          this.$store.dispatch("readMessage", this.item.id);
          pushToNotificationUrl(this.item);
        }
      }

      this.$emit("on-close", this.timestamp);
    },
  },
  mounted() {
    this.elmHeight = this.$el.clientHeight;
    if (this.timeout) {
      setTimeout(this.close, this.timeout);
    }
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 991px) {
  .alert {
    width: auto !important;
    margin: 0 10px;

    &.left {
      left: 0 !important;
    }
    &.right {
      right: 0 !important;
    }
    &.center {
      margin: 0 10px !important;
    }
  }
}

#app .alert {
  z-index: 100;
  cursor: pointer;
  position: fixed;
  max-width: max-content;
  box-shadow: -5px 3px 40px 10px rgba(34, 60, 80, 0.2);
  background-color: black;

  &.center {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin: 0 auto;
  }

  &.left,
  &.right,
  &.center {
    margin-top: 20px;
  }

  &.left {
    left: 20px;
  }
  &.right {
    right: 20px;
  }
}

.v-application .success.lighten-5 {
  background-color: #03a439 !important;
  color: white;
  max-width: 516px;
}

.v-application .error.lighten-5 {
  background-color: #c90000 !important;
  color: white;
}

.v-application .newNotification.lighten-5 {
  border: 1px solid #fff;
  background-color: #e9e2fc !important;
  max-width: 500px;

  & .notification-title {
    color: #6c3cea;
  }
}
</style>
