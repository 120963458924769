import axios from "@/plugins/axios";

const urlPath = "/partner/api/v1/account";

const getAccount = () => {
  return axios.get(urlPath);
};

const updateAccount = (data) => {
  return axios.put(`${urlPath}`, data);
};

const updateLogo = (data) => {
  return axios({
    method: "post",
    url: `${urlPath}/logo`,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};

const removeLogo = () => {
  return axios.delete(`${urlPath}/logo`);
};

export default {
  getAccount,
  updateAccount,
  updateLogo,
  removeLogo,
};
