import staffApi from "@/api/staff";
import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  isSubmitting: false,
  member: {},
  staff: [],
  errors: [],
  isResending: false,
  isResetting: false,
  isUpdatingAvatar: false,
  isActivating: false,
  isDeleting: false,
};

const mutations = {
  resetStaffErrors(state) {
    state.errors = [];
  },
  getStaffStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getStaffSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.staff = payload;
  },
  getStaffFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  createMemberStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  createMemberSuccess(state) {
    state.isSubmitting = false;
  },
  createMemberFailed(state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  actionStaffStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  actionStaffSuccess(state, payload) {
    state.isLoading = false;
    state.member = payload;
  },
  actionStaffFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getMemberStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getMemberSuccess(state, payload) {
    state.isLoading = false;
    state.member = payload;
  },
  getMemberFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  removeStaffSuccess(state) {
    state.isLoading = false;
    state.errors = [];
  },
};

const actions = {
  async getStaff(context, payload) {
    try {
      context.commit("getStaffStart");

      const res = await staffApi.getStaff(payload);
      context.commit("getStaffSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getStaffFailed", e.response.data.error);
      return false;
    }
  },

  async createMember(context, payload) {
    try {
      context.commit("createMemberStart");

      await staffApi.createMember(payload);
      context.commit("createMemberSuccess");
      return true;
    } catch (e) {
      console.log(e);
      context.commit("createMemberFailed", e.response.data.error);
      return false;
    }
  },

  async actionStaff(context, payload) {
    try {
      context.commit("actionStaffStart");

      const res = await staffApi.staffAction(
        payload.staffId,
        payload.requestBody
      );
      context.commit("actionStaffSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("actionStaffFailed", e.response.data.error);
      return false;
    }
  },

  async getMember(context, payload) {
    try {
      context.commit("getMemberStart");

      const res = await staffApi.getMember(payload);
      context.commit("getMemberSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getMemberFailed", e.response.data.error);
      return false;
    }
  },

  async removeStaff(context, payload) {
    try {
      context.commit("actionStaffStart");

      await staffApi.removeStaff(payload.staffId);
      context.commit("removeStaffSuccess");
      return true;
    } catch (e) {
      context.commit("actionStaffFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  staffPartnerList(state) {
    return state.staff || [];
  },
  staffList(state) {
    return state.staff?.result || [];
  },
  memberFullName(state) {
    const { first_name, last_name } = state.member;
    return `${first_name} ${last_name}`;
  },
  staffIsEmailConfirmed(state) {
    const confirmation = state.member.is_email_confirmed;
    return confirmation === undefined ? true : confirmation;
  },
  staffFirstNameErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "first_name__required") &&
      result.push(i18n.t("errors.firstNameRequired"));
    errors.find((i) => i === "first_name__invalid") &&
      result.push(i18n.t("errors.firstNameInvalid"));
    return result;
  },
  staffLastNameErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "last_name__required") &&
      result.push(i18n.t("errors.lastNameRequired"));
    errors.find((i) => i === "last_name__invalid") &&
      result.push(i18n.t("errors.lastNameInvalid"));
    return result;
  },
  staffEmailErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    errors.find((i) => i === "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    errors.find((i) => i === "email__exists") &&
      result.push(i18n.t("errors.emailExists"));
    errors.find((i) => i === "email__update_too_often") &&
      result.push(i18n.t("errors.emailUpdTooOften"));
    return result;
  },
  staffRoleErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "role__required") &&
      result.push(i18n.t("errors.roleRequired"));
    errors.find((i) => i === "role__invalid") &&
      result.push(i18n.t("errors.roleInvalid"));
    return result;
  },
  staffIsSuspended(state) {
    return state.member ? state.member.is_suspended : false;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
