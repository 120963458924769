import offeringsApi from "@/api/offerings";
import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  isOfferingLoading: false,
  isUpdating: false,
  isUpdatingPrice: false,
  isUpdatingImage: false,
  offering: {},
  offerings: [],
  errors: [],
};

const mutations = {
  resetOfferingsErrors(state) {
    state.errors = [];
  },
  getOfferingsStart(state) {
    state.offerings = [];
    state.offering = {};
    state.isLoading = true;
    state.errors = [];
  },
  getOfferingsSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.offerings = payload;
  },
  getOfferingsFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getOfferingStart(state) {
    state.offerings = [];
    state.isOfferingLoading = true;
    state.errors = [];
  },
  getOfferingSuccess(state, payload) {
    state.isOfferingLoading = false;
    state.errors = [];
    state.offering = payload;
  },
  getOfferingFailed(state, payload) {
    state.isOfferingLoading = false;
    state.errors = payload;
  },

  createOfferingStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  createOfferingSuccess(state, payload) {
    state.isSubmitting = false;
    state.offering = payload;
  },
  createOfferingFailed(state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  actionOfferingStart(state) {
    state.errors = [];
  },
  actionOfferingSuccess(state, payload) {
    state.offering = payload;
  },
  actionOfferingFailed(state, payload) {
    state.errors = payload;
  },

  updateOfferingStart(state) {
    state.isUpdating = true;
    state.errors = [];
  },
  updateOfferingSuccess(state, payload) {
    state.isUpdating = false;
    state.offering = payload;
  },
  updateOfferingFailed(state, payload) {
    state.isUpdating = false;
    state.errors = payload;
  },

  updateOfferingPriceStart(state) {
    state.isUpdatingPrice = true;
    state.errors = [];
  },
  updateOfferingPriceSuccess(state, payload) {
    state.isUpdatingPrice = false;
    state.offering = payload;
  },
  updateOfferingPriceFailed(state, payload) {
    state.isUpdatingPrice = false;
    state.errors = payload;
  },

  uploadOfferingImageStart(state) {
    state.isUpdatingImage = true;
    state.errors = [];
  },
  uploadOfferingImageSuccess(state, payload) {
    state.isUpdatingImage = false;
    state.offering = payload;
  },
  uploadOfferingImageFailed(state, payload) {
    state.isUpdatingImage = false;
    state.errors = payload;
  },

  deleteOfferingImageStart(state) {
    state.isUpdatingImage = true;
    state.errors = [];
  },
  deleteOfferingImageSuccess(state, imageId) {
    state.isUpdatingImage = false;
    state.offering.media = state.offering.media.filter(function (item) {
      if (item.id !== imageId) {
        return item;
      }
    });
  },
  deleteOfferingImageFailed(state, payload) {
    state.isUpdatingImage = false;
    state.errors = payload;
  },
};

const actions = {
  async getOfferings(context, payload) {
    try {
      context.commit("getOfferingsStart");

      const res = await offeringsApi.getOfferings(payload);
      context.commit("getOfferingsSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOfferingsFailed", e.response.data.error);
      return false;
    }
  },

  async createOffering(context, payload) {
    try {
      context.commit("createOfferingStart");

      const res = await offeringsApi.createOffering(payload);
      context.commit("createOfferingSuccess", res.data);
      return res;
    } catch (e) {
      context.commit("createOfferingFailed", e.response.data.error);
      return e.response;
    }
  },

  async getOffering(context, payload) {
    try {
      context.commit("getOfferingStart");

      const res = await offeringsApi.getOffering(payload);
      context.commit("getOfferingSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOfferingFailed", e.response.data.error);
      return false;
    }
  },

  async actionOffering(context, payload) {
    try {
      context.commit("actionOfferingStart");

      const res = await offeringsApi.offeringAction(
        payload.offeringId,
        payload.requestBody
      );
      context.commit("actionOfferingSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("actionOfferingFailed", e.response.data.error);
      return false;
    }
  },

  async updateOffering(context, { id, data }) {
    try {
      context.commit("updateOfferingStart");

      const res = await offeringsApi.updateOffering(id, data);
      context.commit("updateOfferingSuccess", res.data);
      return res;
    } catch (e) {
      context.commit("updateOfferingFailed", e.response.data.error);
      return e.response;
    }
  },

  async updateOfferingPrice(context, { id, data }) {
    try {
      context.commit("updateOfferingPriceStart");

      const res = await offeringsApi.updateOfferingPrice(id, data);
      context.commit("updateOfferingPriceSuccess", res.data);
      return res;
    } catch (e) {
      context.commit("updateOfferingPriceFailed", e.response.data.error);
      return e.response;
    }
  },

  async uploadOfferingImage(context, { id, data }) {
    try {
      context.commit("uploadOfferingImageStart");
      const res = await offeringsApi.uploadOfferingImage(id, data);
      context.commit("uploadOfferingImageSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("uploadOfferingImageFailed", e.response.data.error);
      return false;
    }
  },

  async deleteOfferingImage(context, { offeringId, imageId }) {
    try {
      context.commit("deleteOfferingImageStart");
      await offeringsApi.deleteOfferingImage(offeringId, imageId);
      context.commit("deleteOfferingImageSuccess", imageId);
      return true;
    } catch (e) {
      context.commit("deleteOfferingImageFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  offeringTitleErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "title__required") &&
      result.push(i18n.t("errors.offeringTitleRequired"));
    errors.find((i) => i === "title__invalid") &&
      result.push(i18n.t("errors.offeringTitleInvalid"));
    return result;
  },

  offeringDescriptionErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "description__invalid") &&
      result.push(i18n.t("errors.offeringDescriptionInvalid"));
    return result;
  },

  offeringCategoriesErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "categories__required") &&
      result.push(i18n.t("errors.offeringCategoriesRequired"));
    errors.find((i) => i === "categories__invalid") &&
      result.push(i18n.t("errors.offeringCategoriesInvalid"));
    return result;
  },

  offeringTypeErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "type__required") &&
      result.push(i18n.t("errors.offeringTypeRequired"));
    errors.find((i) => i === "type__invalid") &&
      result.push(i18n.t("errors.offeringTypeInvalid"));
    return result;
  },

  offeringPriceErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "price__required") &&
      result.push(i18n.t("errors.offeringPriceRequired"));
    errors.find((i) => i === "price__invalid") &&
      result.push(i18n.t("errors.offeringPriceInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
