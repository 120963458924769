import authApi from "@/api/auth";
import { setItem } from "@/helpers/persistanceStorage";
import { i18n } from "@/plugins/i18n";
import router from "@/router/index";

const state = {
  isLoggedIn: false,
  isSubmitting: false,
  errors: [],
};

const mutations = {
  authStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  authSuccess(state) {
    state.isSubmitting = false;
    state.errors = [];
  },
  authFailed(state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },
  setLoggedIn(state, payload) {
    state.isLoggedIn = payload;
  },
  resetAuthErrors(state) {
    state.errors = [];
  },
};

const actions = {
  async login(context, params) {
    try {
      context.commit("authStart");

      const { data } = await authApi.login(params);

      context.commit("setLoggedIn", true);

      context.commit("authSuccess");

      return data;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async otp(context, params) {
    try {
      context.commit("authStart");

      await authApi.otp(params);
      // setItem("jwt_token", response.data.access_token);
      context.commit("setLoggedIn", true);
      context.commit("authSuccess");

      return true;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async otpRecovery(context, params) {
    try {
      context.commit("authStart");

      const response = await authApi.otpRecovery(params);
      setItem("jwt_token", response.data.access_token);
      context.commit("setLoggedIn", true);
      context.commit("authSuccess");

      return true;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async sendNewPassword(context, payload) {
    try {
      context.commit("authStart");

      await authApi.sendNewPassword(payload);
      context.commit("authSuccess");

      return true;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async updatePassword(context, payload) {
    try {
      context.commit("authStart");

      const { data } = await authApi.updatePassword(payload);

      context.commit("setLoggedIn", true);

      context.commit("authSuccess");

      return data;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },

  async confirmEmail(context, payload) {
    try {
      context.commit("authStart");

      const { data, status } = await authApi.confirmEmail(payload);
      context.commit("authSuccess");

      return { data, status };
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return e.response;
    }
  },

  async logout(context) {
    try {
      context.commit("authStart");

      await authApi.logout();
      context.commit("setLoggedIn", false);
      context.commit("authSuccess");
      router.push("/sign-in");
      return true;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);

      return false;
    }
  },
};

const getters = {
  emailErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    errors.find((item) => item === "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    errors.find((item) => item === "credential__invalid") && result.push("");
    return result;
  },
  passwordErrors({ errors }) {
    const result = [];

    errors.find((item) => item === "password__required") &&
      result.push(i18n.t("errors.passwordRequired"));
    errors.find((item) => item === "credential__invalid") && result.push("");

    return result;
  },
  otpErrors(state) {
    const result = [];

    state.errors.find((item) => item === "code__required") &&
      result.push(i18n.t("errors.otpRequired"));
    state.errors.find((item) => item === "code__invalid") &&
      result.push(i18n.t("errors.otpInvalid"));
    state.errors.find((item) => item === "otp__invalid") &&
      result.push(i18n.t("errors.otpInvalid"));
    return result;
  },
  otpRecoveryErrors(state) {
    const result = [];

    state.errors.find((item) => item === "recovery_code__required") &&
      result.push(i18n.t("errors.otpRecoveryRequired"));
    state.errors.find((item) => item === "recovery_code__invalid") &&
      result.push(i18n.t("errors.otpRecoveryInvalid"));

    return result;
  },

  emailForPasswordErrors(state) {
    const result = [];

    state.errors.find((item) => item === "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    state.errors.find((item) => item === "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    state.errors.find((item) => item === "staff__not_found") &&
      result.push(i18n.t("errors.staffNotFound"));
    state.errors.find((item) => item === "password_reset__limit") &&
      result.push(i18n.t("errors.resetPasswordTooOften"));

    return result;
  },

  newPasswordErrors(state) {
    const result = [];

    state.errors.find((item) => item === "password__required") &&
      result.push(i18n.t("errors.newPasswordRequired"));
    state.errors.find((item) => item === "password__invalid") &&
      result.push(i18n.t("errors.passwordInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
