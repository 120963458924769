import axios from "@/plugins/axios";

const urlPath = "/partner/api/v1/offering-orders/";

const getOrders = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const getOrder = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const orderAction = (orderId, data) => {
  return axios.post(`${urlPath}${orderId}/action`, data);
};

export default {
  getOrders,
  getOrder,
  orderAction,
};
