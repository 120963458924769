import commonApi from "@/api/common";

const state = {
  isLoading: false,
  categories: [],
  errors: [],
};

const mutations = {
  resetOfferingsErrors(state) {
    state.errors = [];
  },
  getCategoriesStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getCategoriesSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.categories = payload;
  },
  getCategoriesFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
};

const actions = {
  async getCategories(context, payload) {
    try {
      context.commit("getCategoriesStart");

      const res = await commonApi.getCategories(payload);
      context.commit("getCategoriesSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCategoriesFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
