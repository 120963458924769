<template>
  <div class="notif-header">
    <v-menu
      v-model="modal"
      :close-on-content-click="false"
      :nudge-width="500"
      offset-y
      max-width="500"
      content-class="notification-card"
      nudge-bottom="20"
      :nudge-left="!$vuetify.rtl ? 250 : 0"
      :nudge-right="$vuetify.rtl ? 250 : 0"
      attach=".header"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          height="40"
          width="40"
          text
          icon
          dark
          v-bind="attrs"
          v-on="on"
          class="px-2 me-2"
        >
          <v-badge
            color="error"
            dot
            offset-x="-13"
            offset-y="-2"
            v-if="notificationsCount > 0"
            class="absolute"
          >
          </v-badge>
          <img
            width="24"
            height="24"
            src="@/assets/icons/bell.svg"
            alt="notification"
          />
        </v-btn>
      </template>

      <v-card class="notification-card">
        <div class="d-flex align-center px-5 pt-4 pb-3 notifi-title">
          <div class="f17 font-weight-bold">{{ $t("notification.title") }}</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="modal = false" class=""
            ><img alt="close icon" src="@/assets/icons/close-icon.svg"
          /></v-btn>
        </div>
        <div v-if="isLoading" class="d-flex py-7">
          <v-progress-circular
            color="primary"
            indeterminate
            :size="45"
            class="mx-auto"
          ></v-progress-circular>
        </div>
        <div
          v-else-if="!array.length"
          class="text-center px-5 pt-5 pb-7 gray-1--text"
        >
          {{ $t("notification.notData") }}
        </div>
        <div
          v-else
          style="max-height: 400px"
          class="view-without-scroll px-5"
          id="notifi"
          @scroll="handleScrollRequest"
        >
          <div
            v-for="i in array"
            :key="i.id"
            class="pt-3 pb-4 link notifi-item"
            @click="readNotification(i)"
          >
            <div class="mb-3" v-if="i.data">
              <span>{{
                $t(`notification.${i.type}`, {
                  number:
                    i.data?.offering_order?.number ||
                    i.data?.loan?.number ||
                    i.data?.request?.number ||
                    "",
                  cr_name: i.data?.offering_order?.company?.cr_name || "",
                })
              }}</span>
            </div>
            <div class="d-flex align-center notif-date" v-if="i.date">
              <div class="text-body-2 gray-1--text">
                {{
                  new Date(i.date).toLocaleString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour12: false,
                    hour: "numeric",
                    minute: "numeric",
                  })
                }}
              </div>
              <v-spacer></v-spacer>
              <img
                alt="empty circle"
                class="mx-1"
                v-if="i.seen"
                src="@/assets/icons/read-message.svg"
              />
              <v-sheet
                v-else
                class="primary rounded-pill mx-2"
                width="8"
                height="8"
              ></v-sheet>
            </div>
          </div>
        </div>
        <div
          class="px-5 pt-3 pb-4 notifi-title"
          v-if="array.length && array.filter((i) => !i.seen).length"
        >
          <v-btn @click="readAll" text class="px-1" height="36">
            <img
              width="24"
              height="24"
              src="@/assets/icons/double-check.svg"
              alt="check"
            />
            <span class="f-16 fl-uppercase primary--text">{{
              $t("btn.markAllRead")
            }}</span>
          </v-btn>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { mapState } from "vuex";
import queryString from "query-string";
import { pushToNotificationUrl } from "@/helpers/notifications";

export default {
  data() {
    return {
      modal: false,
      array: [],
      page: 0,
      scrollBlockNotifications: 0,
    };
  },
  mounted() {
    this.getNotificationsList();
    this.$store.dispatch("getNotificationsCount");
    this.$store.dispatch("openWebSocket");
  },
  methods: {
    getQueryParams(page = this.page) {
      return queryString.stringify({
        page,
      });
    },

    getNotificationsList() {
      this.$store.dispatch("getNotifications", this.getQueryParams());
    },
    handleScrollRequest() {
      this.scrollBlockNotifications =
        document.getElementById("notifi").scrollTop;
    },
    readAll() {
      this.$store.dispatch("readAll");
    },
    async readNotification(item) {
      if (!item.seen) {
        await this.$store.dispatch("readMessage", item.id);
      }

      pushToNotificationUrl(item);

      this.modal = false;
    },
  },
  computed: {
    ...mapState({
      notificationsCount: (state) => state.notification.notificationsCount,
      isLoading: (state) => state.notification.isLoading,
      notificationsList: (state) => state.notification.notificationsList,
      newNotification: (state) => state.notification.newNotification,
      isReadingAll: (state) => state.notification.isReadingAll,
      notificationsId: (state) => state.notification.notificationsId,
    }),
  },
  watch: {
    notificationsList(val) {
      if (!val?.pages) {
        return;
      }

      if (val.pages === 0) {
        this.array = [];
        this.array.push(...val.result);
      } else {
        this.array = val.result;
      }
    },
    newNotification(val) {
      if (val) {
        this.$notify({
          item: val,
          title: this.$t("notification.newNotification"),
          message: this.$t(`notification.${val.type}`, {
            number:
              val.data?.offering_order?.number ||
              val.data?.loan?.number ||
              val.data?.request?.number ||
              "",
            cr_name: val.data?.offering_order?.company?.cr_name || "",
          }),
          type: "newNotification",
          horizontalAlign: "right",
          verticalAlign: "top",
          timeout: 6000,
          icon: "",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.notification-card {
  border-radius: 12px !important;
  .notifi-title {
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.04);
  }
  .notifi-item:not(:first-of-type) {
    border-top: 1px solid #d8e5eb;
  }
  .notifi-item:last-of-type .notif-date {
    margin-bottom: 8px;
  }
}
</style>
