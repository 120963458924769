import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/modules/auth";
import profile from "@/store/modules/profile";
import account from "@/store/modules/account";
import staff from "@/store/modules/staff";
import offerings from "@/store/modules/offerings";
import common from "@/store/modules/common";
import orders from "@/store/modules/orders";
import notification from "@/store/modules/notification";
import statistics from "@/store/modules/statistics";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    profile,
    account,
    staff,
    offerings,
    common,
    orders,
    notification,
    statistics,
  },
});
