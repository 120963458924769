import axios from "axios";
import router from "@/router";

const _axios = axios.create({
  baseURL: "",
  headers: {
    "Content-Type": "application/json",
  },
});

_axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        return axios
          .post("/partner/api/v1/refresh")
          .then(() => {
            return _axios.request(err.config);
          })
          .catch((e) => {
            if (e.response.status === 401) {
              router.push("/sign-in").catch(() => {});
            }
            return Promise.reject(e);
          });
      }
    }

    return Promise.reject(err);
  }
);

export default _axios;
