import axios from "@/plugins/axios";

const urlPath = "/partner/api/v1/staff/";

const getStaff = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const createMember = (data) => {
  return axios.post(`${urlPath}`, data);
};

const staffAction = (staffId, data) => {
  return axios.post(`${urlPath}${staffId}/action`, data);
};

const removeStaff = (id) => {
  return axios.delete(`${urlPath}${id}`);
};

export default {
  getStaff,
  createMember,
  staffAction,
  removeStaff,
};
