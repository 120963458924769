import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { vuetify } from "@/plugins/vuetify";
import { i18n } from "@/plugins/i18n";
import Notifications from "@/components/NotificationPlugin";
import Vuelidate from "vuelidate";

import VueClipboard from "vue-clipboard2";

import "@/assets/styles/main.scss";

Vue.config.productionTip = false;
Vue.use(Notifications);
Vue.use(Vuelidate);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

export const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
