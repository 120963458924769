import axios from "@/plugins/axios";

const urlPath = "/partner/api/v1/offering/";

const getOfferings = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const createOffering = (data) => {
  return axios.post(`${urlPath}`, data);
};

const getOffering = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const offeringAction = (offeringId, data) => {
  return axios.post(`${urlPath}${offeringId}/action`, data);
};

const updateOffering = (id, data) => {
  return axios.put(`${urlPath}${id}`, data);
};

const updateOfferingPrice = (id, data) => {
  return axios.put(`${urlPath}${id}/price`, data);
};

const uploadOfferingImage = (id, data) => {
  return axios({
    method: "post",
    url: `${urlPath}${id}/media`,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};

const deleteOfferingImage = (offeringId, imageId) => {
  return axios.delete(`${urlPath}${offeringId}/media/${imageId}`);
};

export default {
  getOfferings,
  getOffering,
  createOffering,
  offeringAction,
  updateOffering,
  updateOfferingPrice,
  uploadOfferingImage,
  deleteOfferingImage,
};
