import accountApi from "@/api/account";
import { i18n } from "@/plugins/i18n";
// import { i18n } from "@/plugins/i18n";

const state = {
  account: {},
  isLoading: false,
  isUpdating: false,
  isUpdatingLogo: false,
  errors: [],
};

const mutations = {
  resetAccountErrors(state) {
    state.errors = [];
  },
  getAccountStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getAccountSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.account = payload;
  },
  getAccountFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  updateAccountStart(state) {
    state.isUpdating = true;
    state.errors = [];
  },
  updateAccountSuccess(state, payload) {
    state.isUpdating = false;
    state.errors = [];
    state.account = payload;
  },
  updateAccountFailed(state, payload) {
    state.isUpdating = false;
    state.errors = payload;
  },

  updateLogoStart(state) {
    state.isUpdatingLogo = true;
    state.errors = [];
  },
  updateLogoSuccess(state, payload) {
    state.isUpdatingLogo = false;
    state.account = payload;
  },
  updateLogoFailed(state, payload) {
    state.isUpdatingLogo = false;
    state.errors = payload;
  },
};

const actions = {
  async getAccount(context) {
    try {
      context.commit("getAccountStart");

      const res = await accountApi.getAccount();
      context.commit("getAccountSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getAccountFailed", e.response.data.error);
      return false;
    }
  },

  async updateAccount(context, payload) {
    try {
      context.commit("updateAccountStart");

      const res = await accountApi.updateAccount(payload);
      context.commit("updateAccountSuccess", res.data);

      return true;
    } catch (e) {
      context.commit("updateAccountFailed", e.response.data.error);

      return false;
    }
  },

  async updateLogo(context, payload) {
    try {
      context.commit("updateLogoStart");
      const res = await accountApi.updateLogo(payload);
      context.commit("updateLogoSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("updateLogoFailed", e.response.data.error);
      return false;
    }
  },

  async removeLogo(context) {
    try {
      context.commit("updateLogoStart");
      const res = await accountApi.removeLogo();
      return res.status;
    } catch (e) {
      context.commit("updateLogoFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  accountEmailErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    errors.find((i) => i === "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    errors.find((i) => i === "email__exists") &&
      result.push(i18n.t("errors.emailExists"));
    errors.find((i) => i === "email__update_too_often") &&
      result.push(i18n.t("errors.emailUpdTooOften"));
    return result;
  },

  accountNameErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "name__invalid") &&
      result.push(i18n.t("errors.accountNameInvalid"));
    errors.find((i) => i === "name__required") &&
      result.push(i18n.t("errors.accountNameRequired"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
